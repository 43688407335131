<template>
    <li>
        <nuxt-link :to="$prismic.linkResolver(lender)">
            <div class="container">
                <PrismicImage
                    :img="lender.data.image"
                    :alt="lender.data.title"
                    w="90"
                />
            </div>
            <div class="pad-wrapper">
                <div v-if="isCorporateLoan" class="container">
                    <span>{{ $translate('duration', 'Löptid') }}</span>
                    <span>{{ model.getDurationString($translate('month_short', 'mån'), $translate('year', 'år')) }}</span>
                </div>

                <div v-else class="container">
                    <span>{{ $translate('interest', 'Ränta') }}</span>
                    <span>{{ model.interestString }}</span>
                </div>

                <div class="container">
                    <span>{{ $translate('max_amount', 'Maxbelopp') }}</span>
                    <span>{{ model.maxAmountString }}</span>
                </div>
            </div>
        </nuxt-link>
    </li>
</template>

<script>
export default {
    props: {
        lender: {
            type: Object,
            required: true
        }
    },
    computed: {
        isCorporateLoan() {
            return this.lender.type === 'lender_corporate';
        },
        model() {
            if (this.isCorporateLoan) {
                return new this.$models.CorporateLoan(this.lender, this.$store);
            }
            return new this.$models.Loan(this.lender, this.$store);
        }
    }
};
</script>

<style lang="scss" scoped>
@import './assets/css/colors';

    .similar-loans {
        width: 100%;

        li {
            margin-bottom: 20px;
            transition: .1s ease-in-out;
            border: 1px solid #f3f3f3;
            text-align: center;
            box-shadow: $bxs;
            background: $white;
            @include border;
            @include border-radius(5px);

            &:hover { box-shadow: 0 4px 10px -2px rgba(0,0,0,.3); }

            a {
                text-decoration: none;
                @include flex;
                @include spacing(padding,4,(top,bottom));
                @include spacing(padding,1,(left,right));
                gap: 0.5rem;

                .container {
                    flex: 1;
                }

                .pad-wrapper {
                    @include flex();
                    flex-grow: 1;
                    gap: 0.5rem;

                    @include device(pad) {
                        flex-direction: column;
                    }
                    @include device(desktop) {
                        flex-direction: row;
                        padding: 0;
                    }

                    .container {
                        @include flex;
                        justify-content: space-between;
                        gap: 0.5rem;
                        flex-direction: column;
                        
                        @include device(pad) {
                            flex-direction: row;
                            padding: 0;
                        }

                        @include device(desktop) {
                            flex-direction: column;
                        }
                    }
                }
                
                div {
                    span {
                        color: $font;
                        display: block;
                        font-size: 13px;
                        line-height: 20px;
    
                        &:last-child {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
</style>
