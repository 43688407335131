<template>
    <li class="list-item">
        <div class="list-item__inner">
            <slot name="info" />

            <div class="container buttons">
                <slot name="apply" />
                <Button
                    size="sm"
                    background="text-green"
                    :text="$translate('more_info', 'Mer information')"
                    @click="expand =! expand"
                />
            </div>

            <a
                v-if="lenderData.data.campaign"
                :href="$helpers.getRedirectUrl(lenderData)"
                class="container-campaign"
                target="_blank"
            >
                <span>{{ lenderData.data.campaign }}</span>
                <i class="fas fa-external-link-alt" />
            </a>

            <ListIcons
                v-if="showIcons"
                :data="lenderModel"
                :model="lenderModel"
                :mortgage-layout="mortgageLayout"
            />

            <transition name="expand">
                <div
                    v-show="expand"
                    class="expand"
                >
                    <slot name="expand" />
                </div>
            </transition>
            <compliance-warning
                v-if="showComplianceWarning"
                class="high-cost"
            />
        </div>

        <div class="list-item__compliance">{{ lenderModel.complianceExample }}</div>
    </li>
</template>

<script>
import ListIcons from '@/components/features/ListIcons';
import ComplianceWarning from '@/components/ComplianceWarning.vue';
export default {
    components: { ListIcons, ComplianceWarning },
    props: {
        lenderData: {
            type: Object,
            required: false,
            default: () => {}
        },
        lenderModel: {
            type: Object,
            required: false,
            default: () => {}
        },
        showIcons: {
            type: Boolean,
            required: false,
            default: true
        },
        mortgageLayout: {
            type: Boolean,
            required: false,
            default: false
        },
        showComplianceWarning: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            expand: false
        };
    }
};
</script>

<style lang="scss" scoped>
    .list-item {
        &__inner::v-deep {
            width: 100%;
            background: $white;
            box-shadow: $bxs;
            @include border-radius(3px);
            @include flex;
            overflow: hidden;

            .container-campaign {
                width: 100%;
                text-decoration: none;
                @include flex;
                padding: 10px;
                font-weight: bold;
                background: $green;
                color: $white;
                @include spacing(padding,2);
                @include font-size(14px,10px);
                &:hover { text-decoration: underline; }

                i { margin: 0 15px; }

                span {
                    @include device(mobile) {
                        flex: 1;
                        text-align: center;
                    }
                }
            }

            .container {
                @include grid(1, 10px);
                text-align: center;
                width: 100%;
                @include font-size(14px);
                @include spacing(padding, 4);

                @include device(pad) {
                    width: auto;
                    flex: 1;
                }


                i { margin-right: 10px; }

                &:first-child {
                    @include flex;
                    max-width: 200px;

                    img {
                        @include size(100px, auto);
                        @include border-radius(3px);
                    }
                }

                span:last-child {
                    font-family: $fontHeader;
                    color: $green;
                }
            }

            .container.buttons {
                @include grid(1, 10px);
                order: 6;
                @include border(top);
                width: 100%;

                @include device(pad) {
                    max-width: 200px;
                    order: 0;
                    border: 0;
                }
            }

            .expand {
                width: 100%;
                @include spacing(padding, 6, (top,bottom));
                @include border(top);

                ul {
                    width: 100%;

                    &:last-child { @include spacing(margin, 6, top); }

                    li {
                        @include grid(2,20px);
                        @include font-size(13px);
                        @include spacing(padding, 2);

                        span {
                            margin: 0;
                            &:first-child { text-align: right; }
                            &:last-child {
                                text-align: left;
                                font-family: $fontHeader;
                            }
                        }

                        &:nth-child(even) { background: $lightgray; }

                        i { margin-right: 10px; }
                    }

                    li.head {
                        color: $green;
                        @include grid(1,0);
                        text-align: center;
                        font-family: $fontHeader;
                    }
                }

                &-inner {
                    @include flex;
                    @include spacing(padding,6,top);

                    .btn { margin: 0 5px; }
                }
            }
        }

        &__compliance {
            width: 100%;
            margin-top: 10px;
            @include font-size(12px);
            font-style: italic;
        }

        .high-cost {

            @include device(mobile) {
                order: 8;
            }

        }
    }
</style>
